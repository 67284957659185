import $ from "jquery";
import store from "@/state/store.js";
import moment from "moment";
import appConfig from "@/app.config";
import NotificationsModal from "./NoficationModal";
import Storage from "../../../services/storage";
var storage = new Storage();
export default {
  components: {
    NotificationsModal
  },
  page: {
    title: "Notification history",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  data() {
    return {
      loading: false,
      payload: {
        type: ""
      }
    };
  },
  computed: {
    tableData() {
      return store.getters["admin/notifications/notifications"].filter(data => {
        return data.status == 1 || data.status == 0;
      }).filter(data => {
        var _storage$getSavedStat;
        // check country from storage if CI then show only CI if not show everything
        if (((_storage$getSavedStat = storage.getSavedState("auth.admin")) === null || _storage$getSavedStat === void 0 ? void 0 : _storage$getSavedStat.country) == "CI") {
          return data.country == "CI";
        } else {
          return true;
        }
      });
    },
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    }
  },
  methods: {
    openNotificationModal() {
      let element = this.$refs.notificationModal.$el;
      $(element).modal("show");
    },
    openActionModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal("show");
    },
    publish(data) {
      this.payload = {
        type: "publish",
        data: data.row
      };
      this.openNotificationModal();
    },
    openUnpublishModal() {
      let element = this.$refs.unpublishModal.$el;
      $(element).modal("show");
    },
    deleteNotification(message) {
      this.payload = {
        message: "Are you sure you want to delete this notification?",
        action: "deleteNotification",
        actionMessage: "Delete",
        data: message.row,
        iconType: "delete",
        index: message.$index
      };
      this.openActionModal();
    },
    unpublishNotification(message) {
      this.payload = {
        message: "Are you sure you want to unpublish this notification?",
        action: "unpublishNotification",
        actionMessage: "Unpublish",
        data: message.row,
        iconType: "delete",
        index: message.$index
      };
      this.openUnpublishModal();
    },
    closedModal() {
      let element = this.$refs.unpublishModal.$el;
      $(element).modal("hide");
      let element2 = this.$refs.actionModal.$el;
      $(element2).modal("hide");
      this.loading = true;
      this.payload = {
        type: ""
      };
      store.dispatch("admin/notifications/getNotifications").finally(() => this.loading = false);
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch("admin/notifications/getNotifications").finally(() => this.loading = false);
  }
};