import store from "@/state/store.js";
import Storage from "../../../services/storage";
var storage = new Storage();
import $ from "jquery";
import industries from "@/helpers/adminMessageGroups.js";
export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      success: "",
      error: "",
      isEditing: false,
      loading: false,
      loadSaving: false,
      loadingPublish: false,
      formData: {
        end_date: "",
        message: "",
        status: "",
        country: ""
      },
      rules: {
        message: [{
          required: true,
          message: "Please Enter your message.",
          trigger: "change"
        }],
        end_date: [{
          required: true,
          message: "Please select end date.",
          trigger: "change"
        }],
        country: [{
          required: true,
          message: "Please select country.",
          trigger: "change"
        }]
      },
      countries: [{
        value: "GH",
        label: "Ghana (GH)"
      }, {
        value: "CI",
        label: "Cote D'Ivoire (CI)"
      }, {
        value: "SN",
        label: "Senegal (SN)"
      }]
    };
  },
  computed: {
    industries() {
      return industries;
    },
    findNumberOfRecipient() {
      let {
        message
      } = this.formData;
      if (message.length == 0) {
        return 0;
      }
      if (message.length <= 160) {
        this.formData.perMessage = 1;
        return 1;
      } else if (message.length > 160 && message.length < 320) {
        this.formData.perMessage = 2;
        return 2;
      } else if (message.length > 320 && message.length < 460) {
        this.formData.perMessage = 3;
        return 3;
      }
    },
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    },
    availableCountries() {
      return this.countries.filter(country => {
        // if disallowed user show only the country of the user
        if (this.disallowedUsers) {
          return country.value === storage.getSavedState("auth.admin").country;
        }
        return true;
      });
    }
  },
  methods: {
    edit() {
      const {
        end_date,
        message,
        country
      } = this.payload.data.row;
      this.formData = {
        end_date: end_date,
        message: message,
        country: country
      };
      this.isEditing = true;
    },
    publish() {
      this.loadSaving = true;
      let payLoad = {
        notification_id: this.payload.data.row.id
      };
      return store.dispatch("admin/notifications/publishNotification", payLoad).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response;
        if ((err === null || err === void 0 || (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadSaving = false);
    },
    Unpublish() {
      this.loadSaving = true;
      let payLoad = {
        notification_id: this.payload.data.row.id
      };
      return store.dispatch("admin/notifications/UnpublishNotification", payLoad).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response2;
        if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadSaving = false);
    },
    hideModal() {
      $("#sendmessagetoclient").modal("hide");
      this.error = "";
      this.success = "";
      this.loading = false;
      this.loadSaving = false;
      this.loadingPublish = false;
      this.formData = {
        end_date: "",
        message: "",
        status: "",
        country: ""
      };
      this.$emit("close");
    },
    save() {
      this.loadSaving = true;
      return store.dispatch("admin/notifications/saveNotification", this.formData).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response3;
        if ((err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadSaving = false);
    },
    saveAndPublic() {
      this.loadingPublish = true;
      this.formData.status = 1;
      return store.dispatch("admin/notifications/saveNotification", this.formData).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response4;
        if ((err === null || err === void 0 || (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : _err$response4.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadingPublish = false);
    },
    editNotification() {
      this.loadingPublish = true;
      this.formData.id = this.payload.data.row.id;
      const stateData = this.payload.data.row;
      stateData.message = this.formData.message;
      stateData.end_date = this.formData.end_date;
      stateData.index = this.payload.data.$index;
      this.formData.stateData = stateData;
      return store.dispatch("admin/notifications/editNotification", this.formData).then(message => {
        this.success = message;
      }).catch(err => {
        var _err$response5;
        if ((err === null || err === void 0 || (_err$response5 = err.response) === null || _err$response5 === void 0 ? void 0 : _err$response5.data) == undefined) {
          this.errors = "Network error try again.";
        }
      }).finally(() => this.loadingPublish = false);
    },
    submitAction(formName, type) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (type == "save") {
            this.save();
          }
          if (type == "publish") {
            return this.saveAndPublic();
          }
          if (type == "edit") {
            return this.editNotification();
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  },
  mounted() {
    this.error = "";
    this.success = "";
    this.loading = false;
    this.isEditing = false;
    this.formData = {
      end_date: "",
      message: "",
      status: "",
      country: ""
    };
    //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
  }
};